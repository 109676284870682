<template>
  <div class="portfolio" id="port">

    <!-- pagebanner small --> 
    <PageBanner></PageBanner>

    <!-- filters -->
    <section class="portfolio-grid section-padding">
      <div class="container container-xl">
        <div class="row">
          <div class="col-lg-12 text-center">
            <div class="section-title">
              <h6>Recent werk</h6>
              <h2>Werk waar we <b>trots</b> op zijn</h2>
            </div>
            <ul class="port-menu recent">
              <li :key="key" v-for="(val, key) in option.getFilterData" class="button"
                :class="[key===filterOption? 'is-checked' : '']" @click="filter(key)">{{key}}
              </li>
            </ul>
          </div>
        </div>
      </div>

      <!-- portfolio layout -->
      <div class="container-fluid">
        <isotope class="row" ref="cpt" :item-selector="'element-item'" :options='option' :list="list"
          @filter="filterOption=arguments[0]">
          <!-- item in for loop -->
          <div class="element-item col-lg-3 col-md-6 col-12 pb-4" v-for="element in list" :key="element.id"
            @click="selected=element" v-bind:class='[element.projectCat]'>
            <div class="portfolio-item" :style="element.projectImg">
              <div class="portfolio-content">
                <h4 class="portfolio-title">{{element.name}}</h4>
                <div class="portfolio-category">{{element.projectCat}}</div>
              </div>
            </div>
          </div>
          <!-- item in for loop -->
        </isotope>
      </div>
    </section>

    <!-- contact --> 
    <ContactForm></ContactForm>

    <!-- footer --> 
    <FooterPeeters></FooterPeeters>
  </div>
</template>

<script>
  import PageBanner from '@/components/PageBanner.vue'
  import isotope from 'vueisotope'
  import ContactForm from '@/components/ContactForm.vue'
  import FooterPeeters from '@/components/Footer.vue'

  export default {
    name: 'Portfolio',
    components: {
      PageBanner,
      isotope,
      ContactForm,
      FooterPeeters,
    },
    mounted() {
      const el = document.body;
      el.classList.remove('removeClass', 'noScroll');
    },
    // sample data
    data() {
        return {
            list: [
                {   
                    id: 1,
                    name: "Project name 1",
                    projectCat: "Technisch",
                    projectImg: "background-image: url('https://images.pexels.com/photos/834892/pexels-photo-834892.jpeg?auto=compress&cs=tinysrgb&dpr=2&h=650&w=940')",
                },
                {   
                    id: 2,
                    name: "Project name 2",
                    projectCat: "Interieur",
                    projectImg: "background-image: url('https://images.pexels.com/photos/1648776/pexels-photo-1648776.jpeg?auto=compress&cs=tinysrgb&dpr=2&h=650&w=940')",
                    metal: true,
                },
                {   
                    id: 3,
                    name: "Project name 3",
                    projectCat: "Exterieur",
                    projectImg: "background-image: url('https://cdn.pixabay.com/photo/2016/06/24/10/47/architecture-1477041_960_720.jpg')",
                },
                {   
                    id: 4,
                    name: "Project name 4",
                    projectCat: "Schetsen",
                    projectImg: "background-image: url('https://images.pexels.com/photos/4792480/pexels-photo-4792480.jpeg?auto=compress&cs=tinysrgb&dpr=2&h=650&w=940')",
                    metal: true,
                },
            ],
            selected: null,
            filterOption: "Alle projecten",
            option: {
              itemSelector: ".element-item",
              getFilterData: {
                "Alle projecten": function() {
                  return true;
                },
                "Schetsen": function(el) {
                  return el.projectCat === "Schetsen";
                },
                "Interieur": function(el) {
                  return el.projectCat === "Interieur";
                },
                "Exterieur": function(el) {
                  return el.projectCat === "Exterieur";
                },
                "Technisch": function(el) {
                  return el.projectCat === "Technisch";
                },
              },
            },
          }
    },
    methods: {
      filter: function(key) {
        this.$refs.cpt.filter(key);
      },
    },
    // for seo 
     metaInfo() {
        return { 
            title: this.$appName+" | Portfolio",
            meta: [
                { name: 'description', content:  'Epiloge is about connecting in your field of interest. Our vision is to help people share their knowledge, work, projects, papers and ideas and build their network through what they do rather where they live, study or work.'},
                { property: 'og:title', content: "Epiloge - Build your network in your field of interest"},
                { property: 'og:site_name', content: 'Epiloge'},
                {property: 'og:type', content: 'website'},    
                {name: 'robots', content: 'index,follow'} 
            ]
        }
      }
  }

</script>

